@import "./variables";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";

@media screen and (min-width: 992px) {
	.navbar {
		padding-bottom: 0px;
	}

	.navbar-nav {
		position: relative;
		z-index: 1;
		white-space: nowrap;
	}

	.navbar-nav .auth-item {
		display: none;
	}

	.navbar-nav > a,
	.navbar-nav > .dropdown > .dropdown-toggle {
		color: #fff;
		position: relative;
		display: inline-block;
		padding: 2em 1.5em 0.5em 1.5em;
		text-decoration: none;
		margin: 0 -7px;
	}

	.nav-dropdown .dropdown-menu {
		border: 5px solid $red;
		margin-top: 0px;
		padding-left: 5px;
		min-width: 7rem;
	}

	.nav-dropdown .dropdown-menu a {
		display: block;
		text-decoration: none;
		color: $primary;
		padding-bottom: 5px;
	}

	.nav-dropdown .dropdown-toggle:has(+ .dropdown-menu > .active)::before {
		border-top: 8px solid $red;
		border-left: 5px solid $red;
		border-right: 5px solid $red;
		border-bottom: none;
		background: #fff;
		margin-bottom: -5px;
	}

	.nav-dropdown .dropdown-toggle:has(+ .dropdown-menu > .active) {
		z-index: 2;
		color: #000;
		font-weight: bold;
	}

	.navbar-nav > a::before,
	.navbar-nav > .dropdown > a::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0em;
		left: 0;
		z-index: -1;
		border-bottom: none;
		//background: #fff;
		//box-shadow: 0 2px hsla(0, 0%, 100%, 0.5) inset;
		transform: perspective(6px) rotateX(1.5deg);
		transform-origin: bottom;
	}

	.navbar-nav a.active {
		z-index: 2;
		color: #000;
		font-weight: bold;
	}

	.navbar-nav a.active::before {
		margin-bottom: -5px;
		border-top-width: 1px;
	}

	.navbar-nav > a.active::before {
		border-top: 8px solid $red;
		border-left: 5px solid $red;
		border-right: 5px solid $red;
		border-bottom: none;
		background: #fff;
	}

	.nav-dropdown .dropdown-menu a::before {
		content: "- ";
	}

	.nav-dropdown .dropdown-menu a.active {
		color: $red;
	}

	.navbar-nav a:hover {
		text-decoration: none;
		color: $red;
	}

	//.show > a.dropdown-toggle:hover,
	//.show > a.dropdown-toggle:focus,
	.navbar-dark .navbar-nav .show > .nav-link,
	.navbar-dark .navbar-nav .nav-link:hover {
		text-decoration: none;
		color: $red;
	}

	.nav-dropdown .dropdown-menu[data-bs-popper] {
		left: -6px;
	}

	/* LOGIN */
	#nav-login-btn {
		position: relative;
		z-index: 1;
		white-space: nowrap;
		min-width: 50px;
	}

	// Used for what??
	/*.nav-login-btn div a {
    color: #fff;
    position: relative;
    display: inline-block;
    padding: 2em 1.5em 1em 1.5em;
    text-decoration: none;
    margin: 0 -7px;
  }*/

	.auth-item {
		color: #fff;
		text-decoration: none;
	}

	.auth-item:hover {
		color: $red;
		text-decoration: none;
	}

	#nav-login-btn a::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0em;
		left: 0;
		z-index: -1;
		border-bottom: none;
		transform: perspective(6px) rotateX(-2deg);
		transform-origin: bottom;
	}

	#nav-login-btn a::before {
		margin-bottom: -10px;
		border-bottom: 5px solid $red;
		border-left: 5px solid $red;
		border-right: 5px solid $red;
		border-top: none;
	}

	#nav-login-btn-split {
		border-left: 4px solid $red;
		height: 120%;
		position: absolute;
		left: 50%;
		//margin-left: -3px;
		top: 0;
	}

	.page-title {
		text-align: left;
		margin-top: $spacer;
		margin-bottom: $spacer;
	}

	.footer-col {
		text-align: left;
		padding-top: $spacer * 3;
		padding-bottom: $spacer * 3;
	}
}

@media screen and (max-width: 991px) {
	.navbar {
		padding-bottom: 15px;
	}

	#nav-login-btn {
		display: none;
	}

	.navbar-nav a {
		color: #fff;
		margin-bottom: 10px;
		text-decoration: none;
		font-size: 1.2rem;
	}

	.navbar-nav a:hover {
		text-decoration: none;
		color: $red;
	}

	.navbar-nav a.active {
		color: $red;
		font-weight: bold;
	}

	.dropdown-menu.show {
		display: grid;
	}

	.dropdown-menu {
		text-align: center;
		background-color: rgba(108, 117, 125, 50%);
	}

	.dropdown-toggle:has(+ .dropdown-menu > .active),
	.navbar-dark .navbar-nav .show > .nav-link {
		color: $red;
	}

	.page-title {
		text-align: center;
		margin-top: $spacer;
		margin-bottom: $spacer;
	}

	.footer-col {
		text-align: center;
		padding-top: $spacer;
		padding-bottom: $spacer;
	}
}

#searchBtn:hover {
	background-color: $red;
	color: #fff;
}

.navbar-brand {
	margin-right: 0px;
}

div.fill {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

div.fill img {
	flex-shrink: 0;
	min-width: 100%;
	min-height: 100%;
}

.main-container {
	min-height: 100vh; /* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 325px; /* max height of your footer */
}

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-image: url("../images/asl-curves.png");
	background-position: center 90%;
	background-size: 120%;
}

.flex-center {
	align-items: center;
	justify-content: center;
}

.badge-medium {
	font-size: 1em;
}

.form-control {
	border-color: $primary;
}

/*[class*="col"] {
  border: 1px solid pink;
}*/

/* Accordion */
.accordion-button {
	background-color: $gray-400;
}

.accordion-button:not(.collapsed) {
	background-color: $gray-400;
}

/* Image Uploader */
.img-card:hover .img-buttons {
	display: block;
}

.img-buttons {
	display: none;
	position: relative;
	bottom: 2.1rem;
	width: calc(100px);
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);
}

.img-buttons * {
	color: white;
}

.nav-tabs .nav-link {
	border: none;
	color: $primary;
	font-weight: bold;
	font-size: 1.1rem;
	padding: 0.5rem 1rem;
	margin: 0.5rem;
	//background-color: $primary;
}

.nav-tabs .nav-link.active {
	color: $primary;
	border-bottom: 4px solid $red;
}

.btn-check:focus,
.btn-outline-primary:focus,
.btn-outline-danger:focus,
.btn-outline-success:focus {
	box-shadow: none;
}

/* Scrollbar */
body {
	overflow: overlay;
}

*::-webkit-scrollbar {
	display: block;
	width: 16px;
}

*::-webkit-scrollbar-button {
	display: none;
}

*::-webkit-scrollbar-track {
	background-color: #00000000;
}

*::-webkit-scrollbar-track-piece {
	background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
	background-color: #00000000;
	border: 5px solid transparent;
	border-radius: 24px;
	box-shadow: 4px 0px 0px 4px #00000040 inset;
}

li {
	margin-bottom: 0.5rem;
}

ul {
	list-style-type: none;
}

div.col li:before {
	content: "\2022";
	font-weight: bolder;
	padding-right: 5px;
}

.section-sign-list li:before {
	content: "§";
	font-weight: normal;
	margin-left: -20px;
}

.section-sign-list {
	list-style-type: none;
}

.header {
	background-image: url("../images/asl-curves.png");
	background-position: right 30%;
	background-size: 120%;
}
