$red: #f50c00;
$orange: #ff6900;
$green: rgb(0, 162, 19);
$blue: rgb(1, 104, 201);
$gray-400: #ced4da;
$gray-800: #343a40;
$primary: $gray-800;
$secondary: $gray-400;
$success: $green;
$info: $blue;
$warning: $orange;
$danger: $red;
$link-color: $info;
$font-weight-bold: 800;
$dark: $gray-800;
